import React, { useEffect, useState } from 'react';
import { obtenir_utilisateur_par_id, reinitialiser_image_profil } from '../../services/utilisateurService';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, Table } from 'react-bootstrap';
import styles from "./fiche-utilisateur.module.css";
import HFAlerte from '../commun/hf-alerte';
import { CONSTANTS } from '../../config/constants';

export default function FicheUtilisateur() {

    const [state, setState] = useState({
        utilisateur: null,
    })
    const params = useParams();

    useEffect(() => {
        !state.utilisateur && obtenir_utilisateur_par_id(params.utilisateur_id, state, setState)
    }, [state, params.utilisateur_id])

    function calculerAge(dateNaissance) {
        const anneeNaissance = parseInt(dateNaissance.slice(0, 4), 10);
        const anneeActuelle = new Date().getFullYear();
        return anneeActuelle - anneeNaissance;
    }

    return (
        <>
            {
                !params.utilisateur_id &&
                <HFAlerte type='danger' texte="Aucun utilisateur trouvé."></HFAlerte>
            }
            {
                state.utilisateur &&
                params.utilisateur_id &&
                <>
                    <Row className={'text-center ' + styles.section_profil}>
                        <Col xs="1" lg="4"></Col>
                        <Col xs="10" lg="4">
                            <Row className={styles.bordure_action + " text-center"}>
                                <Col sm="12">
                                    <img 
                                        className={'img-fluid ' + styles.image_profil}
                                        src={
                                            state.utilisateur.image_profil ? 
                                            CONSTANTS.nomDomaineApi + '/images/profil/' + state.utilisateur.image_profil :
                                            CONSTANTS.nomDomaineApi + '/images/profil/defaut/image-profil-defaut.png'
                                        }
                                        alt="Représente l'utilisateur."
                                    />
                                </Col>
                                <Col sm="12">
                                    <h1 className={styles.nom_utilisateur}>{state.utilisateur.prenom + " " + state.utilisateur.nom}</h1>
                                </Col>
                                {
                                    state.utilisateur.image_profil &&
                                    <Col sm="12">
                                        <Button 
                                            onClick={() => {
                                                reinitialiser_image_profil(state.utilisateur.id, state, setState)
                                            }}
                                            className={styles.hf_bouton_fiche}
                                        >Réinitialiser image de profil</Button>
                                    </Col>
                                }
                                <Col sm="12">
                                    <Link to={"/modifier-utilisateur/" + state.utilisateur.id}>
                                        <Button className={styles.hf_bouton_fiche}>Modifier</Button>
                                    </Link>
                                </Col>
                                <Col sm="12">
                                    <Button 
                                        className={styles.hf_bouton_fiche}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            
                                        }}
                                    >Supprimer</Button>
                                </Col>
                            </Row>
                            <Table variant='dark' striped bordered hover>
                                <tbody>
                                    <tr>
                                        <th>Courriel</th>
                                        <td>{state.utilisateur.courriel}</td>
                                    </tr>
                                    <tr>
                                        <th>Code postal</th>
                                        <td>{state.utilisateur.code_postal}</td>
                                    </tr>
                                    <tr>
                                        <th>Numéro téléphone</th>
                                        <td>{state.utilisateur.numero_telephone}</td>
                                    </tr>
                                    <tr>
                                        <th>Date de naissance</th>
                                        <td>{state.utilisateur.date_naissance} ({calculerAge(state.utilisateur.date_naissance)} ans)</td>
                                    </tr>
                                    <tr>
                                        <th>Date inscription</th>
                                        <td>{state.utilisateur.date_inscription}</td>
                                    </tr>
                                    <tr>
                                        <th>Est incrit à l'infolettre</th>
                                        <td>{state.utilisateur.est_inscrit_infolettre ? "Oui" : "Non"}</td>
                                    </tr>
                                    <tr>
                                        <th>Est actif</th>
                                        <td>{state.utilisateur.est_actif ? "Oui" : "Non"}</td>
                                    </tr>
                                    <tr>
                                        <th>Rôle</th>
                                        <td>{state.utilisateur.nom_role}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs="1" lg="4"></Col>
                    </Row>
                </>
            }
            
        </>
    );
  }