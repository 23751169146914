import { obtenirValeur } from '../utilitaires/localStorageUtil';
import { CONSTANTS } from '../config/constants';
import validator from 'validator';
import axios from 'axios';

export function obtenir_toutes_les_images(state, setState)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/obtenir/image", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "GET",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
        {
          setState({
            ...state,
            listeImageEvenement: res.contenu
          })
          return;
        }
        setState({
          ...state,
          listeImageEvenement: []
        })
      }, 
      (err) => {
        setState({
          ...state,
          listeImageEvenement: []
        })
        console.log(err)
      }
    )
}

export function obtenir_image_evenement_par_id(state, setState, image_id)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/obtenir/image/" + image_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "GET",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
        {
          setState({
            ...state,
            image: res.contenu
          })
          return;
        }
        setState({
          ...state,
          image: []
        })
      }, 
      (err) => {
        setState({
          ...state,
          image: []
        })
        console.log(err)
      }
    )
}

export function ajouter_image(fichier_image, stateListeImage, setStateListeImage, stateFormAjoutImage, setStateFormAjoutImage)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  let formData = new FormData()
  formData.append('fichier_image', fichier_image)
  
  fetch(CONSTANTS.nomDomaineApi + "/ajouter/image", {
      headers: {
        'Authorization':'Bearer ' + token
      },
      method: 'POST',
      body: formData
  })
      .then((res) => { 
        obtenir_toutes_les_images(stateListeImage, setStateListeImage)
        setStateFormAjoutImage({
          ...stateFormAjoutImage,
          alertStatusImage: {
              type:"success",
              texte:"Les images ont bel et bien été ajoutés !"
          }
        })
        setTimeout(() => {
          setStateFormAjoutImage({
            ...stateFormAjoutImage,
            alertStatusImage: {
                type:"",
                texte:""
            }
          })
        }, 3000)
      })
      .catch(
        (e) => { 
          console.log(e)
          setStateFormAjoutImage({
            ...stateFormAjoutImage,
            alertStatusImage: {
                type:"danger",
                texte:"Les images n'ont pas été ajoutés !"
            }
          })
          setTimeout(() => {
            setStateFormAjoutImage({
              ...stateFormAjoutImage,
              alertStatusImage: {
                  type:"",
                  texte:""
              }
            })
          }, 3000)
        }
      )
}

export function modifier_image(state, setState, image_id)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);

  if(validator.isEmpty(state.image.nom ?? ""))
  {
    setState({
      ...state,
      erreurModificationImage: {
        type: "warning",
        valeur: "Veuillez remplir le champ 'nom' !"
      }
    })
    return;
  }
  if(validator.isEmpty(state.image.description ?? ""))
  {
    setState({
      ...state,
      erreurModificationImage: {
        type: "warning",
        valeur: "Veuillez remplir le champ 'description' !"
      }
    })
    return;
  }
  if(state.image.categories.length === 0)
  {
    setState({
      ...state,
      erreurModificationImage: {
        type: "warning",
        valeur: "Veuillez choisir une catégorie !"
      }
    })
    return;
  }
  
  axios.put(
    CONSTANTS.nomDomaineApi + "/modifier/image/" + image_id,
    state.image,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
      }
    }
  )
      .then((response) => { 
          if(response.status === 200)
          {
            setState({
              ...state,
              erreurModificationImage: {
                type: "success",
                valeur: "L'image a été modifié !"
              }
            })
            return;
          }
          setState({
            ...state,
            erreurModificationImage: {
              type: "danger",
              valeur: "L'image n'a pas été modifié à cause d'une erreur !"
            }
          })
      })
      .catch((error) => {
        console.log(error)
        setState({
          ...state,
          erreurModificationImage: {
            type: "danger",
            valeur: "L'image n'a pas été modifié à cause d'une erreur !"
          }
        }) 
      })
}

export function supprimer_image(event, image_id)
{
  event.preventDefault();
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/supprimer/image/" + image_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "DELETE",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
          
      },
      (error) => {
        console.log(error)
      }
    )
}