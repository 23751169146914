import React from 'react'
import styles from './titre-page.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useNavigate  } from 'react-router-dom';

export default function TitrePage(props) {

    const navigate = useNavigate();

    return (
        <Row>
            <Col lg="3" xs="1"></Col>
            <Col lg="6" xs="10" className="text-center">
                <h1 className={ styles.titre_page }>
                    {
                        props.showBackButton &&
                        <Button
                            className={styles.back_button}
                            onClick={() => {
                                navigate(props.showBackButton);
                            }}
                        >Retour au tableau</Button>
                    }
                    {props.titre_page}
                </h1>
            </Col>
            <Col lg="3" xs="1"></Col>
        </Row>
    );
  }