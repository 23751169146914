import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from './formulaire-ajouter-categorie-image.module.css';
import { ajouter_categorie_image } from '../../services/categorieImageService';
import HFAlerte from '../commun/hf-alerte';

export default function FormulaireAjoutCategorieImage() {

    const [state, setState] = useState({
        alertFormAjoutCategorie: {
            type:"",
            texte:""
        },
        categorie_image: {
            nom: "",
            description: ""
        }
    })

    return (
        <>
            <Row className={styles.conteneur_alerte}>
                <Col lg="4" xs="1"/>
                <Col lg="4" xs="10" className="text-center">
                {
                    state.alertFormAjoutCategorie.texte &&
                    <HFAlerte 
                        aucunRow={true}
                        type={state.alertFormAjoutCategorie.type}
                        texte={state.alertFormAjoutCategorie.texte}
                    />
                }
                </Col>
                <Col lg="4" xs="1"/>
            </Row>
            <Row className={styles.conteneur_form_ajout_evenement}>
                <Col lg="4" xs="1"></Col>
                <Col lg="4" xs="10" className="text-center">
                    <Form 
                        onSubmit={
                            (event) => {
                                event.preventDefault();
                                ajouter_categorie_image(
                                    state,
                                    setState
                                )
                            }
                        } 
                        noValidate
                    >
                        <Form.Group className={"mb-3 " + styles.champ_form}>
                            <Form.Label>Nom de la catégorie d'image*</Form.Label>
                            <Form.Control 
                                onChange={
                                    (event) => 
                                    { 
                                        setState({
                                            ...state,
                                            categorie_image: {
                                                ...state.categorie_image,
                                                nom: event.target.value
                                            }
                                        })
                                    }
                                } 
                                type="text" 
                                placeholder="Festival Été de Québec" 
                                value={state.categorie_image.nom} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_description_evenement">
                            <Form.Label>Description de la catégorie*</Form.Label>
                            <Form.Control
                                onChange={
                                    (event) => 
                                    { 
                                        setState({ 
                                            ...state,
                                            categorie_image: {
                                                ...state.categorie_image,
                                                description: event.target.value
                                            }
                                        })
                                    }
                                } 
                                value={state.categorie_image.description} 
                                as="textarea" 
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Ajouter la catégorie d'image
                        </Button>
                    </Form>
                </Col>
                <Col lg="4" xs="1"></Col>
            </Row>
        </>
    );
  }