import React, { useContext } from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IconeChargement from '../composants/commun/icone-chargement';
import FormulaireAjoutCategorieImage from '../composants/ajouter-categorie-image/formulaire-ajouter-categorie-image';
import { AuthContext } from '../contexts/auth-context';

export default function PageAjouterCategorieImage() {
    const [state] = useState({
      meta: {
        title: 'Gestion Emotions | Ajouter une catégorie d\'image'
      } 
    });
    const authContext = useContext(AuthContext);

    return (
      <DocumentMeta {...state.meta}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Container fluid>
            <Navigation></Navigation>
            {
              !authContext.authData.estConnecte &&
              <>
                <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
                <IconeChargement/>
              </>
            }
            {
              authContext.authData.estConnecte &&
              authContext.authData.profil &&
              <>
                <TitrePage showBackButton="/voir-toutes-les-categories-images" titre_page="Ajouter une catégorie d'image"></TitrePage>
                <FormulaireAjoutCategorieImage />
              </>
            }
            <PiedPage></PiedPage>
          </Container>
        </LocalizationProvider>
      </DocumentMeta>
    );
  }