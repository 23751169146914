import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { changer_mon_mot_de_passe, envoyer_code_reinitialisation, envoyer_courriel_reinitialisation, envoyer_sms_reinitialisation, obtenirOptionVerification } from '../../services/connexionService';
import { Link } from 'react-router-dom';
import styles from "./formulaire-mot-de-passe-oublier.module.css";
import HFAlerte from '../commun/hf-alerte';

export default function FormulaireMotDePasseOublier(props) {

    const [state, setState] = useState({
        erreurReinitialisationMotDePasse: {
            type:"",
            texte:""
        },
        courriel: "",
        code:"",
        motDePasse:"",
        motDePasseRepeter:"",
        etapeFormulaire : 0,
        optionsReinitialisation: []
    })

    return (
        <>
            <Row className="text-center">
                <Col lg="4"></Col>
                <Col lg="4">
                    <Link className={styles.bouton_retour_page_connexion} to="/connexion">Retourner à la page de connexion</Link>
                </Col>
                <Col lg="4"></Col>
            </Row>
            
            {
                state.erreurReinitialisationMotDePasse.texte &&
                <Row>
                    <Col lg="4"></Col>
                    <Col lg="4" className="text-center">
                        <HFAlerte 
                            aucunRow
                            type={state.erreurReinitialisationMotDePasse.type} 
                            texte={state.erreurReinitialisationMotDePasse.texte}
                        />
                    </Col>
                    <Col lg="4"></Col>
                </Row>
            }
            {
                state.etapeFormulaire === 0 &&
                <Row className={styles.conteneur_form_reinitialisation}>
                    <Col lg="4"></Col>
                    <Col lg="4" className="text-center">
                        <p>Afin de réinitaliser votre mot de passe, veuillez entrer votre courriel afin d'obtenir vos options de réinitialisation.</p>
                        <Form 
                            onSubmit={
                                (event) => { 
                                    obtenirOptionVerification(event, state, setState)
                                }
                            } 
                            noValidate
                        >
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Votre email*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            courriel: event.target.value,
                                            erreurReinitialisationMotDePasse: {
                                                type:"",
                                                texte:""
                                            }
                                        }) 
                                    }} 
                                    type="email" 
                                    placeholder="exemple@domaine.com" 
                                    autoFocus={true}
                                />
                            </Form.Group>
                            <Button className={styles.bouton_envoyer_courriel_reinitialisation} variant="primary" type="submit">
                                Commencer la réinitialiation de mon mot de passe
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="4"></Col>
                </Row>
            }
            {
                state.etapeFormulaire === 1 &&
                <Row className={styles.conteneur_form_reinitialisation}>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <p>Voici vos options de réinitialisation :</p>
                        {
                            state.optionsReinitialisation && state.optionsReinitialisation.possede_courriel &&
                            <Button
                                onClick={(event) => {
                                    envoyer_courriel_reinitialisation(event, state, setState)
                                }}
                                className={styles.bouton_envoyer_courriel_reinitialisation} 
                                variant="primary"
                            >
                                Envoyer un courriel de réinitialisation
                            </Button>
                        }
                        {
                            state.optionsReinitialisation && state.optionsReinitialisation.possede_telephone &&
                            <Button
                                onClick={(event) => {
                                    envoyer_sms_reinitialisation(event, state, setState)
                                }}
                                className={styles.bouton_envoyer_courriel_reinitialisation} 
                                variant="primary"
                            >
                                Recevoir un code par SMS
                            </Button>
                        }
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
            {
                state.etapeFormulaire === 2 &&
                <Row>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Form onSubmit={(event) => { envoyer_code_reinitialisation(event, state, setState) }} noValidate>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Code de réinitialisation*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            code: event.target.value,
                                            erreurReinitialisationMotDePasse: {
                                                type:"",
                                                texte:""
                                            }
                                        }) 
                                    }} 
                                    type="text" 
                                    placeholder="123456" 
                                    autoFocus={true}
                                />
                            </Form.Group>
                            <Button className={styles.bouton_envoyer_courriel_reinitialisation} variant="primary" type="submit">
                                Envoyer le code de réinitialisation
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
            {
                state.etapeFormulaire === 3 &&
                <Row>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Form onSubmit={(event) => { changer_mon_mot_de_passe(event, state, setState) }} noValidate>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Nouveau mot de passe*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            motDePasse: event.target.value,
                                            erreurReinitialisationMotDePasse: {
                                                type:"",
                                                texte:""
                                            }
                                        }) 
                                    }} 
                                    type="password" 
                                    autoFocus={true}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Répéter votre nouveau mot de passe*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            motDePasseRepeter: event.target.value,
                                            erreurReinitialisationMotDePasse: {
                                                type:"",
                                                texte:""
                                            }
                                        }) 
                                    }} 
                                    type="password"
                                />
                            </Form.Group>
                            <Button className={styles.bouton_envoyer_courriel_reinitialisation} variant="primary" type="submit">
                                Changer mon mot de passe
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
        </>
    );
  }