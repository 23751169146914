import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from './formulaire-ajouter-categorie-evenement.module.css';
import { ajouter_categorie_evenement } from '../../services/categorieEvenementService';
import HFAlerte from '../commun/hf-alerte';

export default function FormulaireAjoutCategorieEvenement() {

    const [state, setState] = useState({
        alertFormAjoutCategorie: {
            type:"",
            texte:""
        },
        categorie_evenement: {
            nom: "",
            description: ""
        }
    })

    return (
        <>
            {
                state.alertFormAjoutCategorie.texte && 
                <Row className={styles.conteneur_alerte}>
                    <Col lg="4" xs="1"/>
                    <Col lg="4" xs="10" className="text-center">
                        <HFAlerte
                            aucunRow={true}
                            type={state.alertFormAjoutCategorie.type}
                            texte={state.alertFormAjoutCategorie.texte}
                        />
                    </Col>
                    <Col lg="4" xs="1"/>
                </Row>
            }
            <Row className={styles.conteneur_form_ajout_evenement}>
                <Col lg="3" xs="1"></Col>
                <Col lg="6" xs="10" className="text-center">
                    <Form 
                        onSubmit={
                            (event) => {
                                event.preventDefault();
                                ajouter_categorie_evenement(
                                    state,
                                    setState
                                )
                            }
                        } 
                        noValidate
                    >
                        <Form.Group className={"mb-3 " + styles.form_champ}>
                            <Form.Label>Nom de la catégorie d'événement*</Form.Label>
                            <Form.Control 
                                onChange={
                                    (event) => 
                                    { 
                                        setState({
                                            ...state,
                                            alertFormAjoutCategorie: {
                                                type: "",
                                                texte: ""
                                            },
                                            categorie_evenement: {
                                                ...state.categorie_evenement,
                                                nom: event.target.value
                                            }
                                        })
                                    }
                                } 
                                type="text" 
                                placeholder="Festivals, Concerts, etc." 
                                value={state.categorie_evenement.nom} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description de la catégorie d'événement*</Form.Label>
                            <Form.Control
                                placeholder="ex: Événements reliés au festival du Québec." 
                                onChange={
                                    (event) => 
                                    { 
                                        setState({ 
                                            ...state,
                                            alertFormAjoutCategorie: {
                                                type: "",
                                                texte: ""
                                            },
                                            categorie_evenement:{
                                                ...state.categorie_evenement,
                                                description: event.target.value
                                            }
                                        })
                                    }
                                } 
                                value={state.categorie_evenement.description} 
                                as="textarea" 
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Ajouter la catégorie d'événement
                        </Button>
                    </Form>
                </Col>
                <Col lg="3" xs="1"></Col>
            </Row>
        </>
    );
  }